export const goToRoot = () => {
  globalThis.location.assign(
    globalThis.location.href.replace(globalThis.location.pathname, '/auth/'),
  );
};

export const isSameBaseDomain = (targetUrl: string): boolean => {
  try {
    const target = new URL(targetUrl, globalThis.location.origin);

    const currentBaseDomain = globalThis.location.hostname
      .split('.')
      .slice(-2)
      .join('.');
    const targetBaseDomain = target.hostname.split('.').slice(-2).join('.');

    return currentBaseDomain === targetBaseDomain;
  } catch {
    return false;
  }
};
