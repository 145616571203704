/* eslint-disable */
import {IsOptional, IsNotEmpty, MaxLength, MinLength, IsEmail, IsCreditCard, IsUrl, Range, IsValidPhoneNumber, RegularExpression, IsBoolean, IsString, IsDate, IsDateString, IsTime, IsInt, IsNumberString, IsDecimal, IsEnum, IsArray, Min, Max} from "@templafy/validator/decorators";
export enum AuthenticationMethodType { Email = "email", AzureAd = "azureAd", Saml2 = "saml2" }
export enum AuthenticationPrompt { None = "none", Login = "login" }
export interface IActionResult {}
export class AcceptInviteDto {@IsNotEmpty() @MaxLength(100) @IsString() firstName!: string; @IsNotEmpty() @MaxLength(100) @IsString() lastName!: string; @IsNotEmpty() @MaxLength(100) @MinLength(8) @IsString() password!: string; @IsNotEmpty() @IsString() returnUrl!: string;}
export class AuthenticationMethodDto {@IsNotEmpty() @IsString() identifier!: string; @IsNotEmpty() @MaxLength(50) @IsString() displayName!: string; @IsOptional() @IsEnum(AuthenticationMethodType) type!: AuthenticationMethodType;}
export class EmailSignInDto {@IsNotEmpty() @MaxLength(100) @MinLength(3) @IsEmail() @IsString() email!: string; @IsNotEmpty() @IsString() password!: string; @IsOptional() @IsString() returnUrl?: string; @IsOptional() tenantId?: string;}
export class RedirectResponseDto {@IsOptional() @IsString() location!: string;}
export class RequestPasswordResetDto {@IsNotEmpty() @MaxLength(100) @MinLength(3) @IsEmail() @IsString() email!: string; @IsOptional() tenantId?: string; @IsOptional() @IsString() redirectUri?: string;}
export class ResetPasswordDto {@IsNotEmpty() passwordResetId!: string; @IsNotEmpty() @MaxLength(100) @MinLength(8) @IsString() password!: string; @IsNotEmpty() @IsString() confirmPassword!: string; @IsOptional() @IsString() redirectUri?: string;}
export class SearchAccessibleTenantsByEmailDto {@IsNotEmpty() @IsString() email!: string; @IsOptional() @IsUrl() redirectUri?: string;}
export class SignInDto {@IsNotEmpty() @IsString() returnUrl!: string; @IsOptional() @IsString() authenticationMethodId?: string; @IsOptional() @IsString() userId?: string; @IsOptional() prompt?: AuthenticationPrompt;}
export class UserActivationDto {@IsNotEmpty() activationId!: string; @IsNotEmpty() @MaxLength(100) @IsString() firstName!: string; @IsNotEmpty() @MaxLength(100) @IsString() lastName!: string; @MaxLength(100) @MinLength(8) @IsOptional() @IsString() password!: string; @IsOptional() @IsString() confirmPassword!: string;}
export class UserIdentificationDto {@IsNotEmpty() @IsEmail() @IsString() email!: string; @IsOptional() tenantId?: string; @IsOptional() @IsString() redirectUri?: string;}
export class UserTenantDto {@IsOptional() @IsString() tenantId!: string; @IsOptional() @IsString() tenantName!: string; @IsOptional() @IsUrl() tenantDomain!: string; @IsOptional() @IsUrl() signInUri!: string;}



