import { tracking as baseTracking } from '@templafy/tracking';

export class AuthenticationTracking {
  constructor(private tracking: typeof baseTracking) {}

  public trackCurrentPageView() {
    const pageUrl = AuthenticationTracking.getPageUrlToTrack();
    this.tracking.trackPageView('Authentication.WebApp', pageUrl);
  }

  private static getPageUrlToTrack(): string {
    let pageUrl = '';
    try {
      const parameters = new URLSearchParams(globalThis.location.search);
      if (parameters.has('email')) {
        const email = AuthenticationTracking.anonymizeEmail(
          parameters.get('email'),
        );
        parameters.set('email', email);
        const url = new URL(globalThis.location.href);
        url.search = decodeURIComponent(parameters.toString());
        pageUrl = url.toString();
      }
    } catch {
      const [url] = globalThis.location.href.split('?');
      pageUrl = url;
    }
    return pageUrl;
  }

  private static anonymizeEmail = (email: string | null): string => {
    if (email === null) {
      return '';
    }
    const [user, host] = email.split('@');
    const userPrefix = user.slice(0, 2);
    const userSuffix = user.length > 3 ? user.at(-1) : '';
    return `${userPrefix}***${userSuffix}@${host}`;
  };
}

export const authenticationTracking = new AuthenticationTracking(baseTracking);
