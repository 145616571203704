import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';
import { Tooltip } from '@templafy/ui/components/tooltip/Tooltip';
import { IconTooltip } from '@templafy/ui/icons/IconTooltip';
import { Button } from '@templafy/ui/components/button/Button';
import accessDeniedImage from '../resources/images/access_denied.png';
import { goToRoot, isSameBaseDomain } from '../utilities/navigation';
import { OperationText } from './OperationText';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const SingleSignOnErrorPage: React.FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const operationId = queryParameters.get('operationId') ?? '';
  const errorMessage = queryParameters.get('errorMessage') ?? '';
  const loginUrl = queryParameters.get('loginUrl') ?? '';
  const translator = useTranslator();

  let translatedErrorMessage;
  if (errorMessage) {
    try {
      translatedErrorMessage = translator.singleSignOnServiceError({
        errorMessage: globalThis.atob(errorMessage),
      });
    } catch {
      return null;
    }
  }

  return (
    <AuthenticationStandaloneLayout
      title={translator.singleSignOnTitle()}
      landscapeImage={accessDeniedImage}
      portraitImage={accessDeniedImage}
    >
      <Text style={{ display: 'inline' }}>
        {translator.singleSignOnMessage()}
        &nbsp;
        {translatedErrorMessage && (
          <Tooltip text={translatedErrorMessage} placement="bottom">
            <IconTooltip size="small" />
          </Tooltip>
        )}
      </Text>
      <Text as="p">{translator.singleSignOnHelpMessage()}</Text>
      <OperationText id={operationId} />
      <Button
        testId="button"
        action="primary"
        onClick={() => {
          if (loginUrl && isSameBaseDomain(loginUrl)) {
            globalThis.location.href = loginUrl;
          } else {
            goToRoot();
          }
        }}
        style={{ marginTop: 14 }}
      >
        {translator.singleSignOnTryAgain()}
      </Button>
    </AuthenticationStandaloneLayout>
  );
};
